'use client';

import { ErrorView } from './components/error/error';

export const metadata = {
  title: 'Reload the Page!',
};

export default function Error() {
  return <ErrorView />;
}
